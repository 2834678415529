import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import GridItem from "./../../components/Grid/GridItem";
import CustomInput from "./../../components/CustomInput";
import validationSchema from "./validationSchema";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";

const Contact = () => {
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSubmit = async (values) => {
    const reqConfig = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    };

    try {
      await fetch(
        "https://ikjt94yc9h.execute-api.eu-west-1.amazonaws.com/prod/contact-form",
        reqConfig
      )
        .then((response) => response.json())
        .then((result) => {
          const { body } = result;
          const { success, message } = JSON.parse(body);
          console.log({ body });
          if (success) {
            setIsError(false);
            setIsSuccess(true);
          } else {
            throw message;
          }
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      setIsError(true);
      setIsSuccess(false);
      console.log({ error });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container justify="center" alignItems="center" spacing={3}>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CustomInput
            id="name"
            name="name"
            fullWidth
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            inputProps={{
              placeholder: "Name",
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <CustomInput
            id="email"
            name="email"
            fullWidth
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            inputProps={{
              placeholder: "Email",
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <CustomInput
            id="message"
            name="message"
            fullWidth
            label="Message"
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            inputProps={{
              placeholder: "Message",
            }}
            formControlProps={{
              fullWidth: true,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Button color="primary" variant="contained" fullWidth type="submit">
            Send
          </Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {isError && (
            <Alert severity="error">
              Oops! Your form failed to submit, please try again.
            </Alert>
          )}
          {isSuccess && (
            <Alert severity="success">
              Thank you for contacting us! We'll get back to you as soon as
              possible.
            </Alert>
          )}
        </GridItem>
      </Grid>
    </form>
  );
};

export default Contact;
